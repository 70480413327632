import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import { graphql, Link } from "gatsby";
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import { IntroProject, Intro, IntroImage } from '../../../components/Intro/Intro';
import { Section, Container, CSSGrid, Col } from '../../../components/Grid';
import { MainProject, CardStaticImg, CardLink, CardContainer, CardBody } from '../../../components/Card';
import { ButtonGhost } from '../../../components/Button';
import { designTokens } from '../../../components/Theme/designTokens';
import projects from '../../../data/projects';
import { IconBox, IconCard } from '../../../components/IconBox';
import { isLoggedIn } from "../../../utilities/services/auth";
import MdPerson from 'react-ionicons/lib/MdPerson';
import MdFlag from 'react-ionicons/lib/MdFlag';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Masonite" mdxType="SEO" />
    <IntroProject projectName="Masonite" projectDescription="Connecting doors to the cloud and simplifying home-remodeling" projectCaption="Masonite has been known worldwide for its commitment to product innovation, manufacturing excellence and customer service, while also constantly expanding its diverse line of interior and exterior doors to meet any design or budget." projectYear="2019 - Present" projectImage="masonite-preview" mdxType="IntroProject" />
    <Section section="Projects" mdxType="Section">
  <Container mdxType="Container">
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
        <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
          <h5>Overview</h5>
          <p>At Masonite I help build cross-platform design systems and products - focusing on establishing a process of lean user research and working directly with software engineers, product management, and other stakeholders. We help build products that help push Masonite's business model into new areas.</p>
          <p>My time is heavily spent with researching problems, ideating with engineers/stakeholders, building interactive prototypes, and drinking too much coffee ☕️.</p>
          <IconCard cardTitle="My Role" cardText="Solo Product Designer on multiple, cross-platform product experiences for the Advisar platform, Masonite's IoT platform, and tertiary consumer-level products" mdxType="IconCard">
            <MdPerson fontSize={designTokens.space[5]} color={designTokens.colors.base.grey0} mdxType="MdPerson" />
          </IconCard>
          <IconCard cardTitle="Specifications" cardText="Help enable design thinking, user research, and innovation to a legacy business model - allowing us to create more meaningful and logical products" mdxType="IconCard">
            <MdFlag fontSize={designTokens.space[5]} color={designTokens.colors.base.grey0} mdxType="MdFlag" />
          </IconCard>
          <p><strong>Select one of the projects below to learn more:</strong></p>
          <br />
        </Col>
        <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      </CSSGrid>
      <CSSGrid mdxType="CSSGrid">
        <Col col={1 / 2} media={{
            phone: 1,
            tablet: 1 / 2
          }} mdxType="Col">
          <MainProject title="Door Configurator" link={'/work/masonite/door-configurator'} image="door-configurator-preview" mdxType="MainProject">
            <h5 style={{
                marginTop: '0'
              }}>
              Door Configurator
            </h5>
            <p>Creating compatible doors through a simpler, responsive, and accessible interface</p>
            <ButtonGhost color={isLoggedIn() ? ({
                theme
              }) => theme.highlightedText : ({
                theme
              }) => theme.visited} mdxType="ButtonGhost">{isLoggedIn() ? 'View Project' : 'Private'}</ButtonGhost>
          </MainProject>
        </Col>
        <Col col={1 / 2} media={{
            phone: 1,
            tablet: 1 / 2
          }} mdxType="Col">
          <MainProject title="Advisar" link={'/work/masonite/advisar'} image="advisar-preview" mdxType="MainProject">
            <h5 style={{
                marginTop: '0'
              }}>
              Advisar
            </h5>
            <p>An eCommerce management platform for contractors and internal team members</p>
            <ButtonGhost color={isLoggedIn() ? ({
                theme
              }) => theme.highlightedText : ({
                theme
              }) => theme.visited} mdxType="ButtonGhost">{isLoggedIn() ? 'View Project' : 'Private'}</ButtonGhost>
          </MainProject>
        </Col>
        <Col col={1 / 2} media={{
            phone: 1,
            tablet: 1 / 2
          }} mdxType="Col">
          <MainProject title="Masonite Connected" link={'/work/masonite/masonite-connected'} image="mc-preview" mdxType="MainProject">
            <h5 style={{
                marginTop: '0'
              }}>
              Masonite Connected
            </h5>
            <p>An "Internet of Things" experience that connects a door to a user's smart home</p>
            <ButtonGhost color={isLoggedIn() ? ({
                theme
              }) => theme.highlightedText : ({
                theme
              }) => theme.visited} mdxType="ButtonGhost">{isLoggedIn() ? 'View Project' : 'Private'}</ButtonGhost>
          </MainProject>
        </Col>
      </CSSGrid>
  </Container>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      